<template>
  <div class="item" @click="isUserModal = true">
    <v-img :src="`${ApiUrl}v1/images${user.photo}`" alt="user" width="220" height="260"/>
    <p class="item__fio">{{user.name}}</p>
    <p class="item__role last_line" :style="{marginBottom}">{{user.position}}</p>

    <v-dialog class="dialog" v-model="isUserModal" max-width="776px" @click:outside="isUserModal = false">
      <v-card class="modal">
        <v-card-actions class="close-btn">
          <button @click="isUserModal = false"><v-icon class="close-icon">mdi-close</v-icon></button>
        </v-card-actions>

        <div class="iframe-container">
          <v-img :src="`${ApiUrl}v1/images${user.photo}`" alt="user" width="220" height="260"/>
          <p class="item__fio">{{user.name}}</p>
          <p class="item__role">{{user.position}}</p>
          <p class="item__details">{{user.details}}</p>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ApiUrl} from "@/global";

export default {
  name: 'UserItem',

  data() {
    return {
      isUserModal: false,
      ApiUrl
    }
  },

  props: {
    user: {
      type: Object,
    },
    marginBottom: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.item {
  min-height: 350px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: $border-radius;

  .v-image {
    min-height: 260px;
    border-radius: $border-radius;
  }

  &__fio {
    font-weight: 500;
    font-size: 18px;
    margin-top: 10px;
    max-width: 220px;
  }

  &__role {
    font-size: 16px;
    color: #9FA2AC;
    max-width: 220px;
    margin-bottom: 0 !important;
  }
  &__details {
    white-space: pre-wrap;
    line-height: 23px;
    font-size: 16px;
    margin-top: 32px;
  }
  .last_line{
    margin-bottom: 56px !important;
  }
}

.modal {
  padding: 10px 56px 56px 56px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 776px;
}

.iframe-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .item__fio{
    max-width: none;
    margin-top: 18px;
    text-align: center;
  }
  .item__role{
    max-width: none;
    text-align: center;
  }
  .v-image {
    border-radius: $border-radius;
  }
}

.close-btn{
  width: 100%;
  margin-right: -50px;
  display: flex;
  justify-content: flex-end;
  padding: 0 0 10px 0 !important;
}

@media screen and (max-width: 1100px) {
  .item {
    max-width: 320px;
    margin-right: 10px;
  }
}
</style>
