<template>
  <section class="block inline-grid-container container-padding">
      <div class="block-title" :class="{ page: isPage }">
        <h3>{{$t('aboutFund')}}</h3>
      </div>

      <div :class="[{ 'page-text': isPage }, 'main-task']">
        <p>{{baseInfo.about}}</p>
<!--        <p class="read-more" @click="isFull=true" v-if="!isFull">{{$t('readMore')}}</p>-->
        <p class="fade additional">{{baseInfo.about_additional}}</p>
        <div class="docs">
          <div v-for="(doc, i) in baseInfo.documents" :key="i" class="doc-item" @click="openPdf(doc.doc)">
              <v-img :lazy-src="pdfIcon" :src="pdfIcon" contain class="doc-image mr-4"
                     :alt="$t('ConstituentDocuments')"
              />
              <p class="green-link docs-link">{{doc.text}}</p>
          </div>
        </div>
      </div>

      <div :class="[isPage? 'left':'right']">
        <div v-for="(doc, i) in baseInfo.documents" :key="i" class="doc-item" @click="openPdf(doc.doc)">
          <v-img :lazy-src="pdfIcon" :src="pdfIcon" contain class="doc-image mr-4"
                 :alt="$t('ConstituentDocuments')"
          />
          <p class="green-link">{{doc.text}}</p>
        </div>
      </div>
    </section>
</template>

<script>
import img from '@/assets/img/about_fond_img.svg'
import pdfIcon from '@/assets/img/pdf_icon.svg'
import { mapState } from 'vuex';
import {ApiUrl} from "@/global";

export default {
  name: 'AboutFond',
  data() {
    return {
      img,
      pdfIcon,
      ApiUrl
    }
  },

  computed: {
    ...mapState(['baseInfo']),
  },

  props: {
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openPdf(name) {
      window.open(`${ApiUrl}v1/doc${name}`, '_blank');
    }
  }
};
</script>

<style scoped lang="scss">
.block{
  margin-bottom: 120px !important;
}
.block-title.page {
  display: none;
}

.page-text {
  p{
    font-size: 18px;
  }
}

.about-fond {
  margin-bottom: 110px;
}

.about-fond.page {
  margin-top: 40px;
  margin-bottom: 0;
  .main-task {
    max-width: 800px;
  }
}

.main-task {
  max-width: 580px;

  grid-column: 5 / 11;
  p {
    white-space: pre-line;
  }
  .additional {
    transition: .3s;
    margin-top: 20px;
  }
}

.block1 p,
.block2 p {
  line-height: 30px;
}

.right {
  display: flex;
  flex-direction: column;
  max-width: 165px;
  margin: 0 auto;
}

.right-image {
  flex: none;
  width: 54px;
  height: 54px;
  margin-top: 10px;
}

.doc-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  .doc-image {
    max-width: 33px;
    max-height: 35px;
  }
}

.left {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}

.read-more {
  display: none;
}
@media screen and (max-width: 1100px) {
  .page-text {
    p{
      font-size: 16px;
    }
  }
  .about-fond {
    margin-bottom: 50px;
    flex-direction: column;
  }
  .block{
    margin-bottom: 70px !important;
  }

  .main-task {
    max-width: 100%;
    .block2 {
      display: none;
    }
    .block2.full {
      display: block;
      transition: .3s;
    }
  }

  .right {
    display: none;
  }

  .left {
    display: none;
  }
  .read-more {
    display: inline-flex;
    color: $text-blue;
    border-bottom: 1px dashed $text-blue;
  }
}

@media (max-width: 1100px) {
  .main-task {
      grid-column: 1/13 !important;
    padding-left: 0 !important;
  }

  .docs {
    .doc-item {
      margin-right: 10px;
      width: fit-content;
    }
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -74px;
    margin-top: 25px;
  }

  .doc-item {
    display: flex;
    align-self: center;
  }

  /*.docs-link {
    max-width: 115px;
  }*/
}

@media (min-width: 1101px) {
  .docs {
    display: none;
  }
}

@media (min-width: 376px) and (max-width: 1100px){
  .container-padding{
    padding-right: $medium-container-margin !important;
  }
}

@media (max-width: 375px){
  .container-padding{
    padding-right: $small-container-margin !important;
  }
}

</style>
