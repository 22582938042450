<template>
  <section class="block container-padding">
    <div class="inline-grid-container">
    <div class="block-title">
      <h3 :class="[{active: tab===1}, 'block-title-item']" @click="tab = 1">{{$t('soviet')}}</h3>
      <h3 :class="[{active: tab===2}, 'fond-team', 'block-title-item']" @click="tab = 2">{{$t('team')}}</h3>
    </div>

    <div class="block-content dynamic-content">
      <div class="tab-contents-item" v-show="tab === 1">
        <swiper
          ref="mySwiper" :options="swiperOptions" class="swiper"
          :delete-instance-on-destroy="true"
          :cleanup-styles-on-destroy="true"
        >
          <swiper-slide v-for="el in baseInfo.trustee" :key="el.id" class="slider__slide">
            <UserItem :user="el" margin-bottom="60px"/>
          </swiper-slide>
          <div class="swiper-scrollbar" slot="pagination"></div>
        </swiper>
      </div>
      <div class="tab-contents-item" v-show="tab === 2">
        <swiper
          ref="mySwiper" :options="swiperOptions" class="swiper"
          :delete-instance-on-destroy="true"
          :cleanup-styles-on-destroy="true"
        >
          <swiper-slide v-for="el in baseInfo.teams" :key="el.id" class="slider__slide">
            <UserItem :user="el" margin-bottom="60px"/>
          </swiper-slide>
          <div class="swiper-scrollbar" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    </div>
    <div class="mobile">
      <BoardBlock :trustee="baseInfo.trustee"/>
      <TeamBlock :teams="baseInfo.teams"/>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import UserItem from '../../../components/UserItem.vue';
import TeamBlock from '../../../components/TeamBlock.vue';
import BoardBlock from '../../../components/BoardBlock.vue';

export default {
  name: 'Team',
  data() {
    return {
      tab: 1,
    }
  },

  computed: {
    ...mapState(['swiperOptions']),
    ...mapState(['baseInfo']),
  },

  components: { UserItem, TeamBlock, BoardBlock, Swiper, SwiperSlide },

};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.mobile{
  display: none;
}

.team-wrapper {
  margin-bottom: 175px;
}

.block-content {
  min-height: 408px;
}
.block-title-item {
  color: #A0A0A0;
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
}
.active{
  color: $text;
  font-weight: 600;
}

.fond-team {
  white-space: nowrap;
  border-top-color: #0DB14B;
  border-top-style: solid;
  border-top-width: 2px;
  max-width: 216px;
  padding-top: 15px;
  margin-top: 15px;

}

.tab-contents {
  width: 64%;
}

.tabs {
  margin-right: 65px;
}

.tab {
  display: inline-block;
  color: #9FA2AC;
  font-size: 23px;
  font-weight: 500;
  border-bottom: 1px solid transparent;
  max-width: 205px;
  box-sizing: border-box;
  padding: 15px 0;
  transition: .3s;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover, &.active {
    font-weight: 600;
    color: $text;
    border-color: $green;
    transition: .3s;
  }
}

.tab-contents-item {
  display: flex;
  height: 100%;
}

.slider__slide {
  width: 240px;
}

@media screen and (max-width: 1100px) {
  .team-wrapper {
    flex-direction: column;
    margin-bottom: 60px;
  }

  .mobile{
    display: block;
  }

  .block-title {
    padding: 0;
    margin-bottom: 25px;
    display: none;
  }

  .block-content {
    display: none;
  }

  .inline-grid-container {
    grid-template-rows: 1fr 1fr;
  }

  .tab-contents {
    display: none;
  }

  .board {
      grid-area: 1/1/2/13;
  }
  .team {
    grid-area: 2/1/3/13;
  }
}

</style>
