<template>
  <div class="fond-purpose">
    <div class="fond-purpose__title" @click="openDialog">
      <v-img
        :src="fundPurpose"
        class="fond-purpose__img"
        alt="main"
      />
      <p>{{$t('mainAboutFundVideo')}}</p>
    </div>
    <IFrameDialog :url="url" ref="dialog"/>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IFrameDialog from "@/components/IFrameDialog.vue";

export default {
  name: "FundVideo",

  props: {
    fundPurpose: { type: String },
    url: { type: String }
  },

  methods: {
    openDialog() {
      this.$refs.dialog.openDialog()
    },
  },

  computed: {
    ...mapState(['appWidth']),
  },

  components: {IFrameDialog},
}
</script>

<style scoped lang="scss">

.fond-purpose {
  display: flex;
  align-items: center;
  margin-top: 32px;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 5;
  grid-column-end: 9;
  .fond-purpose__img {
    max-width: 51px;
    margin-right: 25px;
  }
  &__title{
    display: flex;
    align-items: center;
    cursor: pointer;
    p{
      white-space: break-spaces;
      max-width: 278px;
    }
  }
}


@media (max-width: 340px) {
  .fond-purpose__title{
    p{
      white-space: normal;
    }
  }
}

@media (max-width: 1100px) {
  .fond-purpose{
    grid-column: 1/13;
    margin-top: 24px;
  }
}

</style>
