<template>
  <div class="team">
    <div class="block-title">
      <h3>{{$t('team')}}</h3>
    </div>
    <swiper
      ref="mySwiper1" :options="swiperOptions" class="swiper"
      :delete-instance-on-destroy="true"
      :cleanup-styles-on-destroy="true"
    >
      <swiper-slide v-for="el in teams" :key="el.id" class="slider__slide">
        <UserItem :user="el" margin-bottom="60px"/>
      </swiper-slide>
      <div class="swiper-scrollbar" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapState } from 'vuex';
import UserItem from './UserItem.vue';

export default {
  name: 'TeamBlock',

  computed: {
    ...mapState(['swiperOptions']),
    swiper() {
      return this.$refs.mySwiper1.$swiper
    },
  },

  components: { UserItem, Swiper, SwiperSlide },

  props: {
    teams: {},
    isShow: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.team {
  display: none;
}

.team.isShow {
  display: block;
}

.slider__slide {
  width: 230px;
}

.block-title.isShow {
  padding-left: 0;
  margin: 50px 0 30px;
h3 {
  max-width: 100%;
}
}
@media screen and (max-width: 1100px) {
  .block-title {
    padding: 0;
    margin-bottom: 25px;
  }

  .team {
    display: block;
  }
}

</style>
